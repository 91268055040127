import React, { useState, useEffect, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'underscore';

import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';

import {Tab, Button, Modal, Header, Input, Label, Message, Dropdown, Popup, Checkbox, Icon} from 'semantic-ui-react';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import ChoosePhotoContainer from 'modules/misc/ChoosePhotoContainer.js';
import styleDef from '../style.js';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js'
import { DateTimePicker } from 'react-widgets';
import moment from 'moment'
import { cropperDimensions } from 'utils/Constants.js';
import LoadSpinner from 'modules/loading/LoadSpinner.js';
import UploadImagePlaceholder from "img/upload-image-placeholder.jpg";
import RewardDetailsPreview from "img/reward-details-preview.jpg";

const languages = [
    {key:'en',  text:'English',  value:'en'},
    {key:'en_ca',  text:'English (Canada)',  value:'en_ca'},
    {key:'fr',  text:'Français', value:'fr'},
];

const categories = [
    {key:'Raffle',  text:'Raffle',  value:'Raffle'},
    {key:'Points',  text:'Points', value:'Points'},
    {key:'Offer',  text:'Offer', value:'Offer'},
    {key:'Charity',  text:'Charity',  value:'Charity'},
    {key:'Gift Card',  text:'Gift Card', value:'Gift Card'},
    {key:'e-Resource',  text:'e-Resource', value:'e-Resource'},
]

const categoryToRewardType = {
    // contests: 'VRS', // not sure VRS_NO_REWARD or VRS_PRIZE
    Raffle: 'RAFFLE',
    // Points: 'GEMS', // not sure
    Offer: 'OFFER',
    Charity: 'DONATION',
    'Gift Card': 'GIFT_CARD'
}

const currencies = [
    {key:'cad',  text:'CAD', value:'CAD'},
    {key:'usd',  text:'USD',  value:'USD'},
]

const priorities = [
    {key:null,  text:'None', value:null},
    {key:'high',  text:'High', value:0},
    {key:'medium',  text:'Medium',  value:1},
    {key:'low',  text:'Low',  value:2},
]

const providerActivityRewardActions = [
    {key:null,  text:'None', value:null},
    {key:'click',  text:'Click', value:'CLICK'},
    {key:'referral',  text:'Referral',  value:'REFERRAL'},
    {key:'rewarded_referral',  text:'Rewarded Referral',  value:'REWARDED_REFERRAL'},
]

const providerActivityActionTypes = [
    {key: 'default',  text:'Default', value: 'default'},
    {key:'tab',  text:'Tab', value:'tab'},
    {key:'external',  text:'External',  value:'external'},
    {key:'deeplink',  text:'Deep link',  value:'deeplink'},
]

const chevronIcon = {
    borderStyle: 'solid',
    borderWidth: '0.25em 0.25em 0 0',
    display: 'inline-block',
    height: '1rem',
    width: '1rem',
    transform: 'rotate(45deg)'
}

var style = {
    ...styleDef,
    saveButton: {
        ...buttons.short,
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    updateButton: {
        ...buttons.short,
        float: 'right',
        marginLeft: spacings.small,
        marginRight: spacings.small,
    },
    deleteButton: {
        ...buttons.short,
        marginLeft: spacings.small,
        marginRight: spacings.small,
        color: colors.warningRed,
        backgroundColor: '#fff',
    },
    uploadButton: {
        ...buttons.short,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
    },
    imagePreview: {
        display: 'block',
        boxShadow: shadow,
        borderRadius: '9px',
        width: '200px',
        height: '200px',
        objectFit: 'contain',
        margin: spacings.small,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    textarea: {
        resize: 'none',
        height: '100px',
        width: '100%',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36, 38, 0.15)',
    },
    columns: {
    },
    column: {
        display: 'inline-block',
        width: '30%',
    },
    star: {
        color:colors.themeBlue,
        position: 'relative',
        top: '2px',
    },
    
    charactersLeftDesc: {
        float : 'right',
        marginBottom: '10px'
    },
    datePicker: {
        display: 'inline-block',
        // margin: "1rem"
    },
    chevronIcon,
    chevronIconDown: {
        ...chevronIcon,
        transform: 'rotate(135deg)',
    },
    centerAlign: {
        textAlign: 'center'
    },
    flex: {
       display: "flex", 
       alignItems: "center", 
       gap: "1rem",
       margin: "1rem 0"
    },
    relative: {
        position: 'relative'
    },
    IconOnImage: {
        position: 'absolute',
        top: 0,
        right: '4.5rem',
        fontSize: '2rem',
        // color: 'red',
        cursor: 'pointer'
    },
    gemIcon: {
        color: colors.pointsBlue
    },
    imagePreviewContainer: {
        position: 'absolute',
        top: "80px",
        right: 0,
        zIndex: 10
    },
    imageAssetPreview: {
        display: 'block',
        boxShadow: shadow,
        borderRadius: '9px',
        width: '200px',
        height: '200px',
        objectFit: 'contain',
        margin: spacings.small,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    imagePreview: {
        display: 'block',
        boxShadow: shadow,
        borderRadius: '2px',
        width: '230px',
        height: '500px',
        objectFit: 'contain',
    },
    gridWithPreview: {
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '3fr 1fr'
    }
    
};

const RewardLocalePane = ({t, data, valueChange, onChange}) => (
    <div>
        <br />
        <div>{t('title_prompt')}</div>
        <Popup trigger={
            <Input  value={data ? data.title : ''} 
            name= {'title'}
            placeholder = {t('title_prompt')}
            onChange={onChange}
            onFocus={onChange}
            maxLength = "50"/>
        }
            position = 'right center'
            content = {(50-valueChange) + ' characters left'}
            on='focus' />
        <br />
        <br />
        <div>{t('description_prompt')}</div>
        <Popup trigger={
            <textarea 
            name = {'description'}
            placeholder ={t('description_prompt')}
            style={style.textarea}
            value={data ? data.description : ''} 
            onChange={onChange}
            onFocus={onChange}
            maxLength = "1000" />
        }
            position = 'top right'
            content = {(1000-valueChange) + ' characters left'}
            on='focus' />
        <br />
        <br />
        <div>{t('offer_prompt')}</div>
        <Popup trigger={
                <Input  value={data ? data.offer : ''} 
                name= {'offer'}
                placeholder = {t('offer_prompt')}
                onChange={onChange}
                onFocus={onChange}
                maxLength = "50"/>
            }
            position = 'right center'
            content = {(50-valueChange) + ' characters left'}
            on='focus' />
    </div>
);


const OptionalRewardLocalePane = ({t, data, valueChange, onChange}) => (
    <div>
        <br />
        <div>{t('sku_prompt')}</div>
        <Popup trigger={
            <Input  value={data ? data.sku : ''}
            name= {'sku'}
            placeholder = {t('sku_prompt')}
            onChange={onChange}
            onFocus={onChange}
            maxLength = "50"/>
        }
            position = 'right center'
            content = {(50-valueChange) + ' characters left'}
            on='focus' />
        <br />
        <br />
        <div>{t('brand_prompt')}</div>
        <Popup trigger={
            <Input  value={data ? data.brand : ''}
            name= {'brand'}
            placeholder = {t('brand_prompt')}
            onChange={onChange}
            onFocus={onChange}
            maxLength = "200"/>
        }
            position = 'right center'
            content = {(200-valueChange) + ' characters left'}
            on='focus' />
        <br />
        <br />
        <div>{t('template_prompt')}</div>
        <Popup trigger={
            <Input  value={data ? data.template : ''}
            name= {'template'}
            placeholder = {t('template_prompt')}
            onChange={onChange}
            onFocus={onChange}
            maxLength = "255"/>
        }
            position = 'right center'
            content = {(50-valueChange) + ' characters left'}
            on='focus' />
        <br/>
        <br/>
        <div>{t('terms_prompt')}</div>
        <Popup trigger={
            <textarea 
            name = {'terms'}
            placeholder ={t('terms_prompt')}
            style={style.textarea}
            value={data ? data.terms : ''} 
            onChange={onChange}
            onFocus={onChange}
            maxLength = "4000" />
        }
            position = 'top right'
            content = {(4000-valueChange) + ' characters left'}
            on='focus' />

        <br/>
        <br />
        <div>{t('terms_url_prompt')}</div>
        <Popup trigger={
            <Input  value={data ? data.termsUrl : ''}
            name= {'termsUrl'}
            placeholder = {t('terms_url_prompt')}
            onChange={onChange}
            onFocus={onChange}
            maxLength = "200"/>
        }
            position = 'right center'
            content = {(200-valueChange) + ' characters left'}
            on='focus' />
        <br/>
        <br/>
    </div>
);

const ProviderActivityLocalePane = ({t, data, valueChange, onChange}) => (
    <div>
        <br />
        <Popup trigger={
            <Input  value={data ? data.providerActivityBaseURL : ''} 
            name= {'providerActivityBaseURL'}
            placeholder = {t('linkout')}
            onChange={onChange}
            maxLength = "400"/>
        }
            position = 'right center'
            content = {(400-valueChange) + ' characters left'}
            on='focus' />        
    </div>
);

const EditRewardModalContainer = ({ t, dispatch, resourceScaler, reward: rewardParam, onClose, onRefresh}) => {
    const deleteModalRef = createRef()
    const fileSelector = createRef()
    const [locale, setLocale] = useState('en')
    const [locales, setLocales] = useState({
        en:{brand: '', title:'', description:'', template: '', terms: '', termsUrl: '', offer: '', sku: '', providerActivityBaseURL: ''},
        en_ca:{brand: '', title:'', description:'', template: '', terms: '', termsUrl: '', offer: '', sku: '', providerActivityBaseURL: ''},
        fr:{brand: '', title:'', description:'', template: '', terms: '', termsUrl: '', offer: '', sku: '', providerActivityBaseURL: ''}
      })
    const [price, setPrice] = useState()
    const [currency, setCurrency] = useState('CAD')
    const [resources, setResources] = useState()
    
    const [priority, setPriority] = useState(rewardParam?.priority)
    const [catalog, setCatalog] = useState()
    const [codesFile, setCodesFile] = useState()
    const [codes, setCodes] = useState()
    const [asset, setAsset] = useState(rewardParam?.asset)
    const [bannerAsset, setBannerAsset] = useState(rewardParam?.banner)
    const [companyId, setCompanyId] = useState(rewardParam?.companyId)
    const [valueChange, setValueChange] = useState(0)

    const [category, setCategory] = useState(rewardParam?.analyticsCategory)
    const [isPublic, setIsPublic] = useState(false)
    const [preferredLanguage, setPreferredLanguage] = useState(rewardParam?.locale || 'en')
    const [uploadPhotoProperty, setUploadPhotoProperty] = useState()
    const [hasStepper, setHasStepper] = useState()
    const [hasProgressBar, setHasProgressBar] = useState()
    
    const [hasCode, setHasCode] = useState(false)
    const [maxQuantityPerUser, setMaxQuantityPerUser] = useState()
    const [quantity, setQuantity] = useState()
    const [reward, setReward] = useState()
    const [availableAt, setAvailabeAt] = useState(rewardParam?.availableAt)
    const [endsAt, setEndsAt] = useState(rewardParam?.endsAt)
    const update = Boolean(rewardParam?.id)
    const [isLoading, setIsLoading] = useState(true)
    const [collapseOptionalConfig, setCollapseOptionalConfig] = useState(true);
    const [providerActivity, setProviderActivity] = useState()

    useEffect(() => {
        if(rewardParam) { // update
            setReward({
                ...rewardParam,
                type: categoryToRewardType[category],
                asset: asset?.dataURL,
                banner: bannerAsset?.dataURL,
                enabled: true
            })

            setLocales(locales => {
                if(rewardParam.locales) { // Reward locales
                    Object.values(rewardParam.locales || {}).forEach(translation => {
                        locales[translation.locale] = {
                            ...locales[translation.locale],
                            title: translation.title || '',
                            description: translation.description || '',
                            offer: translation.offer || '',
                            ...(translation.template && {template: translation.template}),
                            ...(translation.terms && {terms: translation.terms}),
                            ...(translation.termsUrl && {termsUrl: translation.termsUrl}),
                            ...(translation.brand && {brand: translation.brand}),
                            ...(translation.sku && {sku: translation.sku}),
                            ...(translation.offerUrl && {offerUrl: translation.offerUrl}),
                        }
                    })

                    if (rewardParam.providerActivity) {
                        rewardParam.providerActivity.locales.forEach(translation => {
                            locales[translation.locale].providerActivityBaseURL = translation.baseURL || ''
                        })
                    }
                }
                return locales
            })
            setPrice(parseInt(rewardParam.price)/100)
            setResources(rewardParam.resources)
            setIsPublic(rewardParam.companyId == null)
            setBannerAsset(rewardParam.banner)
            setAsset(rewardParam.asset)
            setHasCode(!!rewardParam.hasCode)
            setMaxQuantityPerUser(rewardParam.maxQuantityPerUser || null)
            setQuantity(rewardParam.quantity || null)
            setCatalog(rewardParam.catalog)
            setProviderActivity(() => {
                if (!rewardParam?.providerActivity) return undefined
                return ({
                    ...rewardParam.providerActivity,
                    rewardAction: rewardParam.providerActivity.rewardAction.toUpperCase(),
                    sponsorTag: rewardParam.providerActivity?.sponsor?.name
                })
            })
        } else { // create
           setReward({
                currency: 'CAD',
                asset,
                bannerAsset,
                type: categoryToRewardType[category],
                ...spreadLocales(locales),
                enabled: true,
                maxQuantityPerUser: 1,
                resources,
                price,
                priority: priority || 0,
                catalog,
                availableAt,
                expiresAt: endsAt,
                analyticsCategories: category,
                hasCode
            })
        }
        setIsLoading(false)
    }, [rewardParam])

    // update all common values across options
    useEffect(()=>{
        setReward(reward => ({
                ...reward,
                public: isPublic,
                currency,
                asset: asset?.dataURL,
                bannerAsset: bannerAsset?.dataURL,
                ...spreadLocales(locales),
                availableAt,
                expiresAt: endsAt,
                priority: priority || 0,
                catalog,
                type: categoryToRewardType[category],
                analyticsCategory: category,
                quantity,
                maxQuantityPerUser,
                hasCode,
                resources,
                price: price*100,
                providerActivity: providerActivity
                ? {
                    ...providerActivity,
                    baseURL: spreadLocales(locales).providerActivityBaseURL
                }
                : undefined,
            })
        )
    }, [resources, price, availableAt, endsAt, currency, asset, bannerAsset, locales, category, priority, isPublic, hasCode, quantity, maxQuantityPerUser, catalog, providerActivity])

    const spreadLocales = (locales) => {
        // copy en translations to en_ca
        // locales.en_ca = {...locales.en}

        const result = {};
        Object.entries(locales).forEach(([locale, translation]) => {
            Object.entries(translation).forEach(([key, value]) => {
                if(!result.hasOwnProperty(key))
                    result[key] = {[locale]: value || null}
                else if (value)
                    result[key][locale] = value || null
            })
        })

        return result;
    }

    const onSubmit = async (event) => {
        event && event.preventDefault();
        const method = update ? 'updateRewardV2' : 'addRewardV2';

        const rewardData = {
            ...reward,
            ...spreadLocales(locales),
            // rewardCompanyId: reward.companyId,
        
        }

        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        try {
            const result = await DatabaseService[method](rewardData)
            if(codes) {
                const rewardId = update ? reward.id : result.reward.rewardId
                await DatabaseService.uploadRewardCodes({
                    rewardId, 
                    codes
                })
            }
            onRefresh && onRefresh();
            onClose();
        } catch(err) {
            window.alert(err.message || 'An error occurred - check logs');
            console.error(err)
        } finally {
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
        }
    }

    const onDelete = async (deleteYes) => {
        if(deleteYes){
            try {
                await DatabaseService.removeRewardV2({rewardId: reward.id})
                onRefresh && onRefresh();
                onClose();
            } catch(err) {
                window.alert(err.message || 'An error occurred - check logs');
                console.error(err)
            } finally {
                dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            }
        }
    }

    const isValid = () => {
        const selectedLocale = locales[locale];
        return Boolean(selectedLocale) &&
                Boolean(selectedLocale.title) &&
                Boolean(asset) &&
                Boolean(currency) 
                // && _.isFinite(price) &&  _.isFinite(resources) && ((price > 0) || (resources > 0));
    }

    const onChangePreferredLanguage = (event, {value}) => {
        setPreferredLanguage(value)
    }

    const onChangeCategory = (event, {value}) => {
        setCategory(value)
    }

    const onChangeCurrency = (event, {value}) => {
        setCurrency(value)
    }

    const onChangePriority = (event, {value}) => {
        setPriority(value)
    }

    const onChangePrice = (event) => {
        var value = event.target.value;
        setPrice(Math.max(0,value))
    }

    const onChangeMaxQuantityPerUser = () => {
        setMaxQuantityPerUser(maxQuantityPerUser ? null : 1)
    }

    const onChangeQuantity = (event) => {
        var value = event.target.value || null;
        setQuantity(value)
    }

    const onChangeResources = (event) => {
        var value = event.target.value;
        setResources(Math.max(0,value));
    }

    const onChangeProviderActivity = data => {
        if (data.hasOwnProperty('rewardAction') && !data.rewardAction)
            setProviderActivity(null)
        else {
            setProviderActivity({
                ...providerActivity,
                ...data,
            })
        }
    }

    const onChangeLocales = (event, locale) => {
        var key = event.target.name;
        var value = event.target.value;
        setValueChange(value.length)
        setLocales(locales => {
            let newLocales = {...locales}
            newLocales[locale][key] = value
            return newLocales
        })
    }

    const onUploadPhoto = (propertyName) => {
        console.log('onUploadPhoto', propertyName);
        setUploadPhotoProperty(propertyName)
    }

    const onUploadCsv = () => {
        setCodesFile(null)
        setCodes(null)
        fileSelector.current.click();
    }

    const onFileSelect = (event) => {
        const [file,] = event.target.files

        if (file.type != 'text/csv'){
            window.alert('Invalid CSV File')
            return
        }
        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        event.target.value=null
        setCodesFile(file.name)
        const reader = new FileReader();
        reader.onerror= (e) => { 
            window.alert(e)
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false})
        };
        reader.onload = async (e) => {
            dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            setCodes({csv: reader.result})
            setHasCode(true)
        };
        reader.readAsText(file, 'UTF-8');
    }

    const onConfirmPhoto = (photo) => {
        
        switch(uploadPhotoProperty) {
            case 'asset': {
                setAsset(asset => {
                    return {
                        ...asset,
                        dataURL: photo
                    }
                })
                break
            }
            case 'bannerAsset': {
                setBannerAsset(bannerAsset => {
                    return {
                        ...bannerAsset,
                        dataURL: photo
                    }
                })
                break
            }
        }
    }

    const onRemoveAsset= (key) => {
        
        switch(key) {
            case 'asset': {
                setAsset(null)
                break
            }
            case 'bannerAsset': {
                setBannerAsset(null)
                break
            }
        }
    }

    const onChangePublic = () => {
        if (localStorage.master)
            setIsPublic(!isPublic)
    }

    const toggleOptionalConfig = () => {
        setCollapseOptionalConfig(!collapseOptionalConfig);
    };

    if (isLoading) return <LoadSpinner />
    if (localStorage.master !== 'true') return <div>UnAuthorized</div>
    
    return !isLoading && localStorage.master === 'true' && (
        <Modal open style={style.content} onClose={onClose} size='large'>
            <Modal.Header>{update ? t('updateRewardV2_modal_header') : t('addRewardV2_modal_header')}</Modal.Header>
            <Modal.Content>
            <form onSubmit={onSubmit}>
                <Modal.Description>
                    <br />
                    { rewardParam?.id && Boolean(localStorage.master  === 'true') && 
                        <div>
                            <span>{t('rewardId')}:</span>
                            <span style={{margin: "0 8px"}}>{rewardParam.id}</span>
                        </div>
                    }
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div>{t('Primary Language')}</div>
                            <Dropdown options={languages}
                                    value={preferredLanguage}
                                    onChange={onChangePreferredLanguage}
                                    selection
                                    closeOnChange/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div>{t('Category')}</div>
                            <Dropdown options={categories}
                                    disabled={true}
                                    value={category}
                                    onChange={onChangeCategory}
                                    selection
                                    closeOnChange/>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div style={style.gridWithPreview}>
                        <Tab panes={languages.map(language=>({
                            menuItem:language.text,
                            render:() =>
                                <RewardLocalePane t={t} 
                                    locale={language.value}
                                    data={locales[language.value]}
                                    onChange={(event) => onChangeLocales(event, language.value)}
                                    valueChange = {valueChange}
                                />
                            }))}
                        />
                        <div style={style.imagePreviewContainer}>
                            <img style={style.imagePreview} src={RewardDetailsPreview} alt='reward details' />
                        </div>
                        
                    </div>
                    
                    
                    <br />
                    <span>{t('Public')}</span><Checkbox disabled={!Boolean(localStorage.master === 'true')} name='isPublic' style={{margin: "0 1rem"}} checked={isPublic} onClick={onChangePublic} />
                    <div style={style.flex}>
                        <div style={style.column}>
                            <div style={style.relative}>
                                {Boolean(asset && (asset.url || asset.dataURL))
                                    ? <img style={style.imageAssetPreview} src={asset.dataURL || asset.url} alt='Reward Logo' />
                                    :  <img style={style.imageAssetPreview} src={UploadImagePlaceholder} alt='Reward Logo' />
                                }
                            </div>
                            
                            <h5 style={style.centerAlign}>Logo</h5>
                            <Button type='button' 
                                    style={style.uploadButton} 
                                    onClick={()=>onUploadPhoto('asset')}>
                                {t('Upload Image')}
                            </Button>
                        </div>
                        <div style={style.column}>
                            <div style={style.relative}>
                                {Boolean(bannerAsset && (bannerAsset.url || bannerAsset.dataURL))
                                    ? <div>
                                            <img style={style.imageAssetPreview} src={bannerAsset.dataURL || bannerAsset.url} alt='' />
                                            <div style={style.IconOnImage} onClick={()=>onRemoveAsset('bannerAsset')}>
                                                <Icon name="trash" size="tiny" />
                                            </div>
                                        </div>

                                    :  <img style={style.imageAssetPreview} src={UploadImagePlaceholder} alt='Reward Logo' />
                                }
                            </div>
                            <h5 style={style.centerAlign}>Banner</h5>
                            <Button type='button' 
                                    style={style.uploadButton} 
                                    onClick={()=>onUploadPhoto('bannerAsset')}>
                                {t('Upload Image')}
                            </Button>
                        </div>
                    </div>
                    <h3>Cost</h3>
                    <div style={style.flex}>
                        <div>
                            <div>{t('monetary_value_prompt')}</div>
                            <Input name='value'
                                label={{ basic: true, content:'$'}} 
                                type='number' 
                                value={price} 
                                onChange={onChangePrice} />
                        </div>
                        <div>
                            <div>{t('reward_value_prompt')}</div>
                            <Input name='resources' 
                                iconPosition='left'
                                type='number' 
                                value={resources}
                                onChange={onChangeResources}
                            >
                                <Icon name="gem" style={style.gemIcon}/>
                                <input />
                            </Input>
                        </div>
                            
                        {resourceScaler &&
                        <div>
                                <div>{t('reward_scaled_value_prompt')}</div>
                                <Input
                                    labelPosition='left'
                                    type='number' value={resources * resourceScaler || ""} 
                                    >
                            <Label><i style={style.star} className="icon-Optimollar"/> x {resourceScaler}</Label>
                            <input disabled />
                            </Input> 
                        </div> }
                    </div>
                    <br />
                    <br />
                    <div>
                        <h3>Optional Configuration 
                            <Button
                                style = {{backgroundColor: 'white'}}
                                type= 'button'
                                onClick={toggleOptionalConfig}
                            >
                                <span style={collapseOptionalConfig 
                                    ? style.chevronIcon
                                    : style.chevronIconDown}
                                ></span>
                            </Button>
                        </h3>
                        {!collapseOptionalConfig && (
                        <div>
                            <div style={style.flex}>
                                <div>
                                    <div>Currency</div>
                                    <span>
                                        <Dropdown options={currencies}
                                            value={currency}
                                            onChange={onChangeCurrency}
                                            selection
                                            closeOnChange />
                                    </span>
                                </div>
                                <div>
                                    <div>Priority</div>
                                    <span>
                                        <Dropdown options={priorities}
                                            value={priority}
                                            onChange={onChangePriority}
                                            selection
                                            closeOnChange />
                                    </span>
                                </div>
                                <div>
                                    <div>{t('quantity_prompt')}</div>
                                    <Input name='rewardQuantity'
                                        type='number' 
                                        value={quantity} 
                                        onChange={onChangeQuantity} />
                                </div>
                            </div>
                            <h4>Availability</h4>
                            <div style={style.flex}>
                                <div>
                                    <div>Available at</div>
                                    <DateTimePicker
                                        style={style.datePicker}
                                        value={availableAt ? new Date(availableAt) : null}
                                        step={60}
                                        onChange={value=>setAvailabeAt(value)}
                                        onKeyPress={(event)=>{event.preventDefault()}}
                                        min={moment().startOf('hour').toDate()}
                                    />
                                </div>
                                <div>
                                    <div>Ends at</div>
                                    <DateTimePicker
                                        style={style.datePicker}
                                        value={endsAt ? new Date(endsAt) : null}
                                        step={60}
                                        onChange={value=>setEndsAt(value)}
                                        onKeyPress={(event)=>{event.preventDefault()}}
                                        min={moment().startOf('hour').toDate()}
                                    />
                                </div>
                            </div>
                            <h4>Sponsor</h4>
                            <div style={style.flex}>
                                <div>
                                    <div>Id</div>
                                    <Input name= 'providerActivityId'
                                        value={providerActivity?.id}
                                        type='number'
                                        placeholder={t('link_existing_sponsor')}
                                        onChange={(e, {value}) => onChangeProviderActivity({id: value})}
                                    />
                                </div>
                                <div>
                                    <div>Reward Action</div>
                                    <span>
                                        <Dropdown options={providerActivityRewardActions}
                                            value={providerActivity?.rewardAction}
                                            onChange={(e, {value}) => onChangeProviderActivity({rewardAction: value})}
                                            selection
                                            closeOnChange />
                                    </span>
                                </div>
                                <div>
                                    <div>Action Type</div>
                                    <span>
                                        <Dropdown options={providerActivityActionTypes}
                                            value={providerActivity?.actionType}
                                            onChange={(e, {value}) => onChangeProviderActivity({actionType: value})}
                                            selection
                                            closeOnChange />
                                    </span>
                                </div>
                            </div>
                            <div style={style.flex}>
                                <div>
                                    <div>Sponsor Tag</div>
                                    <Input name= 'providerActivityTag'
                                        value={providerActivity?.sponsorTag}
                                        maxLength='50'
                                        onChange={(e, {value}) => onChangeProviderActivity({sponsorTag: value})}
                                    />
                                </div>
                                <div>
                                    <div>Tag</div>
                                    <Input name= 'providerActivityTag'
                                        value={providerActivity?.tag}
                                        maxLength='50'
                                        onChange={(e, {value}) => onChangeProviderActivity({tag: value})}
                                    />
                                </div>
                                <div>
                                    <div>{t('reward_value_prompt')}</div>
                                    <Input name='providerActivityResources' 
                                        iconPosition='left'
                                        type='number'
                                        value={providerActivity?.resources}
                                        onChange={(e, {value}) => onChangeProviderActivity({resources: value})}
                                    >
                                        <Icon name="gem" style={style.gemIcon}/>
                                        <input />
                                    </Input>
                                </div>
                            </div>
                            <div>
                                <Tab panes={languages.map(language=>({
                                    menuItem:language.text,
                                    render:() =>
                                        <ProviderActivityLocalePane t={t} 
                                            locale={language.value}
                                            data={locales[language.value]}
                                            onChange={(event) => onChangeLocales(event, language.value)}
                                            valueChange = {valueChange}
                                        />
                                    }))}
                                />
                            </div>
                            <h4>Misc</h4>
                            <div style={style.flex}>
                                <div>
                                    <span>Limit to 1/user</span>
                                    <Checkbox 
                                        name='maxQuantityPerUser' 
                                        style={{margin: "0 1rem"}} checked={maxQuantityPerUser} 
                                        onClick={onChangeMaxQuantityPerUser} />
                                </div>
                                <div>
                                    <div>
                                        <span>Has Codes</span>
                                        <Checkbox name='hasCode' style={{margin: "0 1rem"}} checked={hasCode} onClick={()=>setHasCode(!hasCode)} />
                                    </div>
                                </div>
                                
                                <div>
                                    <input ref={fileSelector} type="file" style={{display:'none'}} onChange={onFileSelect} />
                                    <Button type='button' style={style.button} 
                                        onClick={onUploadCsv}>{codes ? t('Upload Success') : t('Upload Codes')}
                                    </Button>
                                    <p style={style.centerAlign}>{codesFile}</p>
                                </div>
                            </div>

                            <Tab panes={languages.map(language=>({
                                menuItem:language.text,
                                render:() =>
                                    <OptionalRewardLocalePane t={t} 
                                        locale={language.value}
                                        data={locales[language.value]}
                                        onChange={(event) => onChangeLocales(event, language.value)}
                                        valueChange = {valueChange}
                                    />
                                }))}
                            />
                            
                    </div>)}
                </div>
                    <DeletePromptContainer ref={deleteModalRef} onDeleteConfirmation = {onDelete} />
                    {update &&
                        <Button style={buttons.delete} type='button' onClick={() => {deleteModalRef.current.showDeletePrompt()}}>{t('Delete')}</Button>            
                    }
                    <Button
                        type='submit'
                        style={update ? style.updateButton : style.saveButton}
                        disabled={!isValid()}>
                        {update ? t('Save') : t('Create')}
                    </Button>
                    <br />
                    {Boolean(uploadPhotoProperty) &&
                        <ChoosePhotoContainer
                            onConfirmPhoto={photo=>onConfirmPhoto(photo)}
                            width={cropperDimensions[uploadPhotoProperty].width}
                            height={cropperDimensions[uploadPhotoProperty].height}
                            onClose={onUploadPhoto}
                        />
                    }
                </Modal.Description>
            </form>
            </Modal.Content>
        </Modal>
    )
}

export default connect()(withTranslation('rewards')(EditRewardModalContainer));
