import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, Icon, Header, Checkbox } from 'semantic-ui-react';

import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import Utils from 'utils/Utils.js';
import defaultRewardImage from 'img/optimity-logo.png';

const style = {
    item: {
        textAlign: 'left',
        padding: spacings.base,
    },
    rewardImage: {
        width: '100px',
        maxHeight: '100px',
    },
    rewardRow: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: fontSizes.bodyL,
        lineHeight: '1',
        marginTop: spacings.tiny,
        marginBottom: spacings.tiny,
    },
    checkbox: {
        verticalAlign: 'middle',
        marginLeft: spacings.tiny,
        marginRight: spacings.tiny,
        display: 'inline-block',
    },
    rewardPrice: {
        verticalAlign: 'middle',
        display: 'inline-block',
        width: '80px',
    },
    rewardResources: {
        verticalAlign: 'middle',
        display: 'inline-block',
        width: '80px',
        textAlign:'left'
    },
    star: {
        color:colors.themeBlue,
        position: 'relative',
        top: '2px',
    },
    editButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: '#fff',
        border: 'none',
        boxShadow: 'none',
        fontSize: '30px',
        width: '30px',
        height: '30px',
        padding: '0',
        margin: '0',
    },
    gemIcon: {
        color: colors.pointsBlue
    }

};

const RewardCard = ({t, reward, onToggleReward, onEdit}) => (
    <Card key={reward.id} style={style.item}>
        <div>
            <img src={Utils.deref(reward, 'asset.url', defaultRewardImage)} alt="" style={style.rewardImage} />
            { Boolean(onEdit) && localStorage.master  === 'true' && <Button style={style.editButton} onClick={()=>onEdit(reward)}><Icon name='edit'/></Button>}
        </div>
        <p>{reward.brand && <span>{reward.brand}<br /></span>}{reward.title}</p>

        {
            Boolean(localStorage.master  === 'true') && 
            <div>
                <span>RewardId:</span>
                <span style={{margin: "0 8px"}}>{reward.id}</span>
            </div>
        }
        <div key={reward.id}>
            <div style={style.rewardPrice}><Icon name="dollar"/>{reward.price / 100} {reward.currency?.code}</div>
            <div style={style.rewardResources}><Icon name="gem" style={style.gemIcon}/> {reward.resources}</div>
        </div>
    </Card> 
);

export default withTranslation('reward')(RewardCard);
